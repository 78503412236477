import React, { Suspense, lazy, } from 'react';
import { Routes, Route, } from 'react-router-dom';

const Home = lazy(() => import('./pages/Home'));
const Error = lazy(() => import('./pages/Error'));
const ComingSoon = lazy(() => import('./pages/ComingSoon'));

const App = () => (

    <Routes>
      <Route path="/ComingSoon" component={ComingSoon} />
      <Route exact={true} path="/error/" component={Error} />
      <Route path="/" element={<Home/>} />
      <Route component={Error} />
    </Routes>

);
export default App;
